*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

*::before,*::after {
  box-sizing: border-box;
}

img,picture {
  max-width: 100%;
}
