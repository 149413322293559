.main {
    min-height: 100vh;
    max-width: 100%;
    background-color: #DAD6FF;
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
    }

    &::before {
        clip-path: polygon(100% 33%, 0% 100%, 100% 100%);
        background: #25274D;
        width: 100%;
        height: 100%;
    }
}

.main__container {
    display: flex;
    height: 90vh;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.main__content {
    margin-left: 3.445rem;
}

.text {
    line-height: 55px;

    p {
        color: rgb(157, 155, 155);
        text-transform: uppercase;
        font-size: 22px;
    }

    h1 {
        font-size: 50px;
        text-transform: uppercase;
    }

}

.icons {
    display: flex;

    .icon {
        margin-top: 1rem;
        margin-right: 1.4rem;
        font-size: 35px;
        cursor: pointer;
        color: rgb(157, 155, 155);

        &:hover {
            color: red;
        }
    }
}

.main__img {
    clip-path: circle(45% at 50% 49%);
    cursor: pointer;


    img {
        background: #DAD6FF;
    }
}

.buttons {

    button {
        padding: 15px 40px;
        margin-top: 2rem;
        margin-right: 1rem;
        background: #25274D;
        color: #fff;
        border: 1px solid #fff;
        cursor: pointer;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bolder;
    }
}

.main__container.active {
    background: red;
}



@media (max-width:1200px) {


    .text {
        white-space: nowrap;
        line-height: 30px;

        p {
            font-size: 14px;
        }

        h1 {
            font-size: 30px;
        }

    }


    .buttons button {
        padding: 8px 20px;
    }

    .icons {
        margin-right: 1rem;

        .icon {
            font-size: 25px;
        }
    }


}

@media (max-width:600px) {

    .main__content {
        margin-left: 0;
    }

    .main__container {
        text-align: center;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .main__img img {
        width: 250px;
    }

    .text {
        h1 {
            font-size: 23px;
        }

        p {
            color: #000;
            font-size: 11px;
        }
    }

    .icons {
        margin-left: 3.1rem;

        .icon {
            color: firebrick;

        }
    }
}