.header {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
}


.header__logo {
    margin-left: 3.445rem;
}

.navbar {
    margin: 0 auto;
    margin-right: 3.445rem;
}

ul {
    display: flex;

    li {
        list-style: none;
        margin: 1rem;
        display: flex;


        a {
            text-decoration: none;
            text-transform: uppercase;
            color: #000;

            &:hover {
                border-bottom: 3px solid red;
            }
        }
    }
}

.changer {
    display: flex;
    margin-right: 1.555rem;

    .menu {
        cursor: pointer;
        margin-right: 1rem;
        display: none;
    }
}

.closed .close {
    display: none;
}

@media (max-width:850px) {
    .header {
        justify-content: space-between;
    }

    .navbar {
        display: none;
        transition: 1s ease;
    }

    .navbar.active {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        inset: 0;
        min-height: 100%;
        max-width: 100%;
        background-color: rgba(174, 188, 197);
        transition: 1450ms ease;
    }


    ul {
        margin: 0 auto;
        align-items: center;
        flex-direction: column;
        margin-top: 1rem;

        li {
            margin-block: 2rem;

            a {
                font-size: 20px;
                color: rgb(40, 36, 36);
                font-weight: bolder;
            }
        }
    }


    .changer {
        .menu {
            display: block;
        }
    }

    .closed .close {
        display: block;
    }

    .header__logo {
        margin-left: 2.445rem;
    }


}

@media (max-width:360px) {
    ul {
        margin: 0 auto;
        align-items: center;
        flex-direction: column;
        margin-top: 1rem;

        li {
            margin-block: 2rem;

            a {
                font-size: 20px;
                color: rgb(40, 36, 36);
                font-weight: bolder;
            }
        }
    }

    .changer {
        .menu {
            display: block;
            margin-right: 0;
        }
    }
}