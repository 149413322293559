.section {
    width: 100%;
    min-height: 100vh;
    background: #25274D;
    overflow: hidden;

}


.section__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.5rem;
}

.section__img img{
    margin-top: 1rem;
    width: 520px;
    height: 600px;
    padding: 10px;
    object-fit: cover;
}

.section__content {

    margin: 0 auto;
    line-height: 50px;
    text-align: center;

    h1 {
        color: rgba(174,188,197,.659);
        text-transform: uppercase;
        font-size: 55px;
        margin-bottom: 2.125rem;
    }

    p {
        font-weight: bolder;
        color: rgba(174,188,197,.659);
        text-transform: uppercase;
        border-bottom: 1px solid rgba(174,188,197,.659);;
        cursor: pointer;
        box-shadow: 2px 2px 15px rgba(174,188,197,.659);;
        padding: 6px;
        margin-block: 7px
        ;

        &:hover {
            transform: scale(110%);
            transition: 250ms;
            color: firebrick;
        }
    }
}

@media (max-width:1100px) {
    .section__container {
        flex-direction: column;
        margin: 2.5rem;


    h1 {

        font-size: 25px;
        margin-bottom: 0rem;
    }

    p {
        font-size: 26px;
        margin-block: 25px;
        padding: 25px;
        font-weight: normal;
    }

    }

    .section__img img{
        width: 720px;
        height: 410px;
        padding: 5px;
        object-fit: cover;
    }

}



@media (max-width:735px) {
    .section__img img{
        width: 300px;
        height: 220px;
        padding: 2px;
        object-fit: cover;
    }


    .section__container {
        flex-direction: column;
        margin: 1.5rem;


    h1 {

        font-size: 25px;
    }

    p {
        font-size: 10px;
        margin-block:5px;
        padding: 5px;

    }

    }
}

@media (max-width:335px) {
    .section__container {
        margin: 0;
    h1 {

        font-size: 20px;
        margin-bottom: 0rem;
    }

    p {
        font-size: 12px;
        margin-block: 0px;
        padding: 0px;
        font-weight: normal;
        box-shadow: 2px 2px 5px green;
    }

    }

    .section__img img{
        width: 250px;
        height: 150px;
        padding: 2px;
        object-fit: cover;
    }

}